<template>
<!-- 轮播图管理 -->
  <div class="whiteBg">
    <div class="scrollBox">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
      </template>
      <div class="table_cont">
        <el-table
          :header-cell-style="{background:'#EBF2FE'}"
          :data="rataionList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="名称"
            prop="shuffname">
          </el-table-column>
          <el-table-column
            label="图片"
            prop="furl">
            <template slot-scope="scope">
              <img :src="scope.row.furl" width="180" height="80" :onerror="notImg" class="head_pic"/>
            </template>
          </el-table-column>
          <el-table-column
            label="添加链接"
            prop="shuffurl">
          </el-table-column>
          <el-table-column
            label="排序">
            <template slot-scope="scope" v-if="roleBtn[3].power">
              <el-button @click="sortHand(scope.row, 1)" type="primary" size="mini" plain
                 :disabled="scope.$index === 0" style="display: block">
                上移<i class="el-icon-caret-top el-icon--right"></i>
              </el-button>
              <el-button @click="sortHand(scope.row, 2)" type="primary" size="mini" plain
                 :disabled="scope.$index === rataionList.length-1" style="display: block;margin-left:0px;margin-top: 5px;">
                下移<i class="el-icon-caret-bottom el-icon--right"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[2].power">
                <el-button type="text" size="small"  style="color:#3C84EF;" @click="handleEdit(scope.row)">编辑</el-button>
              </template>
              <template v-if="roleBtn[1].power">
                <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[5]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>

    <!-- 新增弹窗 -->
    <el-dialog title="新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="展示名称" class="foItem"  prop="shuffname">
          <el-input v-model="addfrom.shuffname"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="添加链接"  class="foItem">
          <el-input v-model="addfrom.shuffurl" placeholder="请输入链接"></el-input>
        </el-form-item>
        <el-form-item label="展示图片"  class="showImg" prop="shuffimg">
          <Upload :fileType="'jpg/png/jpeg'"  :refresh="refresh" @uploadSuccess="addImgSuccess"></Upload>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 编辑弹窗 -->
    <el-dialog title="编辑" :visible.sync="editFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <el-form-item label="展示名称" class="foItem"  prop="shuffname">
          <el-input v-model="editfrom.shuffname"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="添加链接"  class="foItem">
          <el-input v-model="editfrom.shuffurl" placeholder="请输入链接"></el-input>
        </el-form-item>
        <el-form-item label="展示图片"  class="showImg" prop="shuffimg">
          <Upload :fileType="'jpg/png/jpeg'" :picture="editfrom.furl" :refresh="refresh" @uploadSuccess="editImgSuccess"></Upload>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { shuffGet, shuffAdd, shuffUpdate, shuffDelete, noticeOper } from '@/api/rotation.js'
export default {
  props: ['roleBtn'],
  components: {
    Upload
  },
  data () {
    return {
      page: 1,
      size: 5,
      total: 0,
      rataionList: [],
      notImg: require('@/assets/img/users.png'),
      addFromShow: false,
      addfrom: {
        shuffname: '',
        shuffurl: '',
        shuffimg: ''
      },
      formRules: {
        shuffname: [
          { required: true, trigger: 'blur', message: '展示名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        shuffimg: [
          { required: true, trigger: 'change', message: '请上传要展示的图片' }
        ],
      },
      refresh: false,
      editFromShow: false,
      editfrom: {},
      editformRules: {
        shuffname: [
          { required: true, trigger: 'blur', message: '展示名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        shuffimg: [
          { required: true, trigger: 'change', message: '请上传要展示的图片' }
        ]
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size
      }
      shuffGet(params).then(res => {
        if (res.status === 1) {
          this.rataionList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.rataionList = []
          this.total = 0
        }
      })
    },
    // 排序移动
    sortHand (row, type) {
      noticeOper({ shuffid: row.shuffid, oper: type }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addImgSuccess (res) {
      this.addfrom.shuffimg = res.fid
    },
    // 新增
    handleAdd () {
      this.addFromShow = true
      this.refresh = !this.refresh
    },
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          shuffname: this.addfrom.shuffname,
          shuffimg: this.addfrom.shuffimg,
          shuffurl: this.addfrom.shuffurl
        }
        shuffAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.addfrom.shuffurl = ''
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
    // 编辑
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = JSON.parse(JSON.stringify(row))
    },
    editImgSuccess (res) {
      this.editfrom.shuffimg = res.fid
    },
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          shuffid: this.editfrom.shuffid,
          shuffname: this.editfrom.shuffname,
          shuffimg: this.editfrom.shuffimg,
          shuffurl: this.editfrom.shuffurl
        }
        shuffUpdate(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        shuffDelete({ shuffid: row.shuffid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    PageChange (page) {
      this.page = page
      this.init()
    }
  }
}
</script>

<style>
.showImg .el-form-item__label {
  width: 100%;
  text-align: left;
}
</style>
