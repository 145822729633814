import { post } from '@/http/http'

// 轮播图查询
export const shuffGet = p => post('api/v1.0/shuffinfo/shuffinfo_get', p)

// 轮播图添加
export const shuffAdd = p => post('api/v1.0/shuffinfo/shuffinfo_add', p)

// 轮播图编辑
export const shuffUpdate = p => post('api/v1.0/shuffinfo/shuffinfo_update', p)

// 轮播图删除
export const shuffDelete = p => post('api/v1.0/shuffinfo/shuffinfo_delete', p)

// 轮播图排序(上移，下移)
export const noticeOper = p => post('api/v1.0/shuffinfo/notice_oper', p)
